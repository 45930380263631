import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./subscriptions.css";
import { Spinner } from "reactstrap";
import { checkIfLoading } from "../../../store/functions";

import moment from "moment";
import PaginationComponent from "../../../components/pagination";
import { LIST_SUBSCRIPTIONS } from "../../../store/subscriptions/actionTypes";
import { listSubscriptions, setSubscription } from "../../../store/actions";
import { userSubscriptionStatus } from "../../../store/types";

const Subscriptions = ({ userId }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });

  const renderStatusOptions = () => {
    return Object.entries(userSubscriptionStatus).map((s, index) => {
      return (
        <option key={index} value={s[1]}>
          {s[1]}
        </option>
      );
    });
  };

  const { subscriptions, loading, count, error } = useSelector((state) => ({
    subscriptions: state.subscription.subscriptions,
    count: state.subscription.count,
    loading: checkIfLoading(state, LIST_SUBSCRIPTIONS),
    authUser: state?.auth?.user,
    error: state?.ui?.error,
  }));

  useEffect(() => {
    dispatch(listSubscriptions({ userId, ...query }));
  }, [query.page]);

  const onSearchSubmit = () => {
    dispatch(listSubscriptions({ userId, ...query }));
  };

  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  const renderSubscriptions = () => {
    if (subscriptions && subscriptions?.length > 0) {
      return subscriptions?.map((sub) => (
        <tr key={sub._id}>
          <td>
            <Link
              onClick={() => dispatch(setSubscription(sub))}
              to={"/subscriptions/" + sub._id}
            >
              {sub?.subscription?.type} - {sub.action}
            </Link>
          </td>
          {/* <td>{sub?.user?.email}</td> */}
          <td>{sub?.charsLimit || 0}</td>
          <td>{sub?.charsCount || 0}</td>
          <td>{sub?.status}</td>
          <td>{sub?.start ? moment(sub?.start).format("DD-MM-YYYY") : "N"}</td>
          <td>
            {sub?.expiry ? moment(sub?.expiry).format("DD-MM-YYYY") : "N"}
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={6}>
            {loading ? (
              <div className="text-center">
                <Spinner color="primary" size="md" />
              </div>
            ) : (
              "No subscriptions found"
            )}
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <h4>User Subscriptions</h4>
      {/* {error ? <div className="alert alert-danger">{error}</div> : ""} */}
      <div className="row mb-3">
        <div className="col-md-2">
          <label>Status</label>
          <select
            onChange={(e) => setQuery({ ...query, status: e.target.value })}
            className="form-control"
          >
            <option value={""}>All</option>
            {renderStatusOptions()}
          </select>
        </div>
        <div className="col-md-3">
          <label>Start</label>
          <input
            type="date"
            className="form-control"
            onChange={(e) => setQuery({ ...query, from: e.target.value })}
          />
        </div>
        <div className="col-md-3">
          <label>Expiry</label>
          <input
            type="date"
            className="form-control"
            onChange={(e) => setQuery({ ...query, to: e.target.value })}
          />
        </div>
        <div className="col-md-2"></div>
        <div className="col-md-2 text-center">
          <label className="d-block">Filters</label>
          <button className="btn btn-primary ml-2" onClick={onSearchSubmit}>
            Search
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Plan</th>
              {/* <th scope="col">Name</th> */}
              <th scope="col">Limit</th>
              <th scope="col">Count</th>
              <th scope="col">Status</th>
              <th scope="col">Start</th>
              <th scope="col">End</th>
            </tr>
          </thead>
          <tbody>{renderSubscriptions()}</tbody>
        </table>
      </div>
      <div>
        <PaginationComponent
          count={count}
          limit={query?.limit}
          page={query?.page}
          setPage={handlePageChange}
        />
      </div>
    </>
  );
};

export default Subscriptions;
