/* eslint-disable no-unused-expressions */
import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PaginationComponent = ({ count, limit, page, setPage, loading }) => {
  const renderPages = (count, limit) => {
    const pagesCount = Math.ceil(count / limit);
    let renderPages = [];
    let pages = [];
    for (let i = 1; i <= pagesCount; i++) {
      pages.push(i);
    }
    if (pages && pages?.length > 0) {
      let slicedPages = [];
      if (page < 5) {
        slicedPages = pages.splice(0, 9);
      } else {
        slicedPages = pages.splice(page - 5, 9);
      }
      renderPages.push(
        <PaginationItem
          disabled={loading || page === 1 ? true : false}
          key={crypto.randomUUID()}
        >
          <PaginationLink
            className="pagination-prev"
            onClick={() => setPage(page - 1)}
            tabIndex="-1"
          >
            <img src="/left-icon.svg" />
          </PaginationLink>
        </PaginationItem>
      );
      slicedPages.map((v, i) => {
        renderPages.push(
          <PaginationItem
            onClick={() => {
              page === v ? "" : setPage(v);
            }}
            active={v === page ? true : false}
            disabled={loading}
            key={crypto.randomUUID()}
          >
            <PaginationLink className="pagination-page">{v}</PaginationLink>
          </PaginationItem>
        );
      });
      renderPages.push(
        <PaginationItem
          disabled={loading || page === pagesCount ? true : false}
          key={crypto.randomUUID()}
        >
          <PaginationLink
            className="pagination-next"
            onClick={() => setPage(page + 1)}
          >
            <img src="/right-icon.svg" />
          </PaginationLink>
        </PaginationItem>
      );
    }
    return renderPages;
  };

  return (
    <Pagination
      aria-label="Page navigation example"
      listClassName="justify-content-center"
      className="custom-pagination"
    >
      {renderPages(count, limit)}
    </Pagination>
  );
};

export default PaginationComponent;
