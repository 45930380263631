import { combineReducers } from "redux"

import auth from "./auth/reducer"
import ui from "./ui/reducer"
import voice from "./voices/reducer"
import user from "./users/reducer"
import log from "./logs/reducer"
import stats from "./stats/reducer"
import subscription from "./subscriptions/reducer"
import ListFailedLongAudio from "./failedLongAudioBooks/reducer" 

import { LOGOUT } from "./auth/actionTypes"

const appReducer = combineReducers({
  auth,
  voice,
  ui,
  user,
  log,
  stats,
  subscription,
  ListFailedLongAudio
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer
