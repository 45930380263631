import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { listLogs } from "../../store/actions";

import "./logs.css";
import DeletePrompt from "../../components/deletePrompt";
import { Col, Modal, Row, Spinner } from "reactstrap";
import { checkIfLoading } from "../../store/functions";

import Pagination from "../../components/pagination";
import { LIST_LOGS } from "../../store/logs/actionTypes";

const Logs = (props) => {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(null);
  const [query, setQuery] = useState({
    name: "",
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    dispatch(listLogs());
  }, []);

  const onFilterSubmit = (page) => {
    let queryStr = "?";
    let newQuery = query;
    if (!page) newQuery.page = 1;
    for (let [key, value] of Object.entries(query)) {
      if (value && value !== "") {
        queryStr += `${key}=${value}&`;
      }
    }
    queryStr = queryStr.slice(0, -1);
    dispatch(listLogs(queryStr));
  };

  useEffect(() => {
    onFilterSubmit(query?.page);
  }, [query.page]);

  useEffect(() => {
    onFilterSubmit();
  }, [query.name]);

  const { logs, loading, count } = useSelector((state) => ({
    logs: state.log.logs,
    count: state.log.count,
    loading: checkIfLoading(state, LIST_LOGS),
    authUser: state?.auth?.user,
  }));

  // 8001222400

  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  const renderLogs = () => {
    if (logs && logs?.length > 0) {
      return logs?.map((log) => (
        <tr key={log._id}>
          <td>{log?.admin?.username}</td>
          <td>{log?.message}</td>
          <td>
            <Link to={"/users/" + log?.user?._id}>{log?.user?.firstName}</Link>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={3}>No logs found</td>
        </tr>
      );
    }
  };

  return (
    <div className="container content">
      <h4>Get all logs</h4>
      <div className="search-row">
        <input
          type="text"
          placeholder="Search by email"
          onChange={(e) => setQuery({ ...query, name: e.target.value })}
          className="form-control"
        />
      </div>
      <div className="table-responsive">
        <table className="table table-stripped">
          <thead>
            <tr>
              <th scope="col">Admin</th>
              <th scope="col">Message</th>
              <th scope="col">User</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={3}>
                  <Spinner color="primary" size="md" />
                </td>
              </tr>
            ) : (
              renderLogs()
            )}
          </tbody>
        </table>
      </div>
      <div>
        <Pagination
          count={count}
          limit={query?.limit}
          page={query?.page}
          setPage={handlePageChange}
        />
      </div>
      <Modal
        centered={true}
        isOpen={deleteModal?.show}
        toggle={() => {
          setDeleteModal(undefined);
        }}
      >
        <DeletePrompt
          loading={loading}
          title={"Are you sure you want to delete?"}
          btnText="Delete"
          func={() => deleteModal?.func()}
          toggle={() => {
            setDeleteModal(undefined);
          }}
        />
      </Modal>
    </div>
  );
};

export default Logs;
