import {
    LIST_FAILED_LONG_AUDIO_BOOKS, FAILED_LONG_AUDIO_ERROR
  } from "./actionTypes";
  
  const initialState = {
    data: [],
    count: 0
  }
  
  export default function ListFailedLongAudio(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case LIST_FAILED_LONG_AUDIO_BOOKS:
        return {
          ...state,
          data: payload?.data,
          count: payload?.count
        };
      case FAILED_LONG_AUDIO_ERROR:
        return {
          ...state,
          error: payload,
          success: null
        }
      default:
        return state;
    }
  }