import {
  RESET,
  SET_ERROR,
  SET_SUCCESS,
  START_ACTION,
  STOP_ACTION
} from './actionTypes';

const initialState = {
  loader: {
    actions: [],
    refreshing: []
  },
  success: null,
  error: null
};

const uiReducer = (state = initialState, { type, payload }) => {
  const { loader } = state;
  const { actions, refreshing } = loader;
  switch (type) {
    case START_ACTION:
      return {
        ...state,
        loader: {
          ...loader,
          actions: [...actions, payload.action]
        }
      };
    case STOP_ACTION:
      return {
        ...state,
        loader: {
          ...loader,
          actions: actions.filter(action => action.name !== payload.name)
        }
      };
    case SET_SUCCESS:
      return { ...state, success: payload };
    case SET_ERROR:
      return { ...state, error: payload };
    case RESET:
      return { ...state, error: null, success: null };
    default:
      return state;
  }
};

export default uiReducer;