import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import { listFailedLongAudioBooks } from "../../store/actions";
import { Spinner, Button } from "reactstrap";
import { checkIfLoading } from "../../store/functions";

import Pagination from "../../components/pagination";
import InfoModal from "../../components/infoModal";
import { LIST_FAILED_LONG_AUDIO_BOOKS } from "../../store/failedLongAudioBooks/actionTypes"

const FailedLongAudioBooks = () => {
  // ** hooks
  const dispatch = useDispatch();

  // ** states
  const [modal, setModal] = useState(false);
  const [fullText, setFullText] = useState("");
  const [query, setQuery] = useState({
    name: "",
    page: 1,
    limit: 10,
  });

  // ** function to handle toggle info modal
  const toggle = (text) => {
    if (text) {
      setFullText(text);
    }
    setModal(!modal);
  } 

  useEffect(() => {
    dispatch(listFailedLongAudioBooks());
  }, []);

  const onFilterSubmit = (page) => {
    let queryStr = "?";
    let newQuery = query;
    if (!page) newQuery.page = 1;
    for (let [key, value] of Object.entries(query)) {
      if (value && value !== "") {
        queryStr += `${key}=${value}&`;
      }
    }
    queryStr = queryStr.slice(0, -1);
    dispatch(listFailedLongAudioBooks(queryStr));
  };

  useEffect(() => {
    onFilterSubmit(query?.page);
  }, [query.page]);

  useEffect(() => {
    onFilterSubmit();
  }, [query.name]);

  const { data, loading, count } = useSelector((state) => ({
    data: state.ListFailedLongAudio.data,
    count: state.ListFailedLongAudio.count,
    loading: checkIfLoading(state, LIST_FAILED_LONG_AUDIO_BOOKS),
    authUser: state?.auth?.user,
  }));

  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  const renderData = () => {
    if (data && data?.length > 0) {
      return data?.map((ele) => (
        <tr key={ele._id}>
          <td>{ele?.data?.params?.voiceApiname}</td>
          <td style={{maxWidth: '200px', fontSize: '16px'}}>
            {
              ele?.data?.params?.pureText.length > 99 ? 
              <>
                {ele?.data?.params?.pureText.slice(0, 100)}...
                <Button type="button" color="primary" onClick={() => toggle(ele?.data?.params?.pureText)}>Show full text</Button>
              </> : 
              ele?.data?.params?.pureText
            }
          </td>
          <td>{ele?.retry}</td>
          <td>{ele?.data?.user[0]?._id}</td>
          <td>{ele?.data?.user[0]?.email}</td>
          <td>{ele?.createdAt?.split("T")[0]}</td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={6}>No failed audios found</td>
        </tr>
      );
    }
  };

  return (
    <div className="container content">
      <h4>Failed long audio books list</h4>
      <div className="table-responsive">
        <table className="table table-stripped">
          <thead>
            <tr>
              <th scope="col">voice Api name</th>
              <th>Text</th>
              <th scope="col">Number of retry</th>
              <th scope="col">User ID</th>
              <th scope="col">User email</th>
              <th scope="col">created At</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={6}>
                  <Spinner color="primary" size="md" />
                </td>
              </tr>
            ) : (
              renderData()
            )}
          </tbody>
        </table>
      </div>
      <div>
        <Pagination
          count={count}
          limit={query?.limit}
          page={query?.page}
          setPage={handlePageChange}
        />
      </div>
      <InfoModal modal={modal} toggle={toggle} title="Full text" description={fullText} />
    </div>
  );
};

export default FailedLongAudioBooks;
