export const tokenConfig = (getState) => {
  let config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  let token
  if(typeof(getState) === 'function') token = getState()?.auth?.token
  else token = getState.token

  if (token) {
    config.headers['Authorization'] = token
  }

  return config
}