import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { goToUser, listUsers } from "../../store/actions";

import "./users.css";
import DeletePrompt from "../../components/deletePrompt";
import { Modal, Spinner } from "reactstrap";
import { checkIfLoading } from "../../store/functions";
import { LIST_USERS } from "../../store/users/actionTypes";

import moment from "moment";
import PaginationComponent from "../../components/pagination";
import SearchUser from "../../components/SearchUser";
import { toast } from "react-toastify";

const Users = (props) => {
  const location = useLocation();
  const search = location.search.split("?search=")[1];
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteModal, setDeleteModal] = useState(null);
  const [query, setQuery] = useState({
    name: "",
    page: 1,
    limit: 10,
  });

  const { users, loading, count, toUser, error } = useSelector((state) => ({
    users: state.user.users,
    count: state.user.count,
    loading: checkIfLoading(state, LIST_USERS),
    authUser: state?.auth?.user,
    toUser: state?.user?.toUser,
    error: state?.ui?.error,
  }));

  // useEffect(() => {
  //   if (search) {
  //     dispatch(listUsers(`name=${search}`))
  //   } else {
  //     dispatch(listUsers())
  //   }
  // }, [search])

  const onFilterSubmit = (page) => {
    let queryStr = "?";
    let newQuery = query;
    if (!page) newQuery.page = 1;
    for (let [key, value] of Object.entries(query)) {
      if (value && value !== "") {
        queryStr += `${key}=${value}&`;
      }
    }
    queryStr = queryStr.slice(0, -1);
    dispatch(listUsers(queryStr));
  };

  useEffect(() => {
    if (search) {
      dispatch(listUsers(`name=${search}`));
    } else {
      onFilterSubmit(query?.page);
    }
  }, [query.page]);

  useEffect(() => {
    if (toUser && !loading) {
      history.push("/users/" + toUser?._id);
    }
  }, [toUser]);

  // useEffect(() => {
  //   onFilterSubmit()
  // }, [query.name])

  const onSearchSubmit = () => {
    onFilterSubmit();
  };

  const onSearchChange = (e) => {
    setQuery({ ...query, name: e.target.value });
  };

  const onGoTo = () => {
    // email regex check
    if (
      !query?.name.match(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      toast.error("Please enter a valid email");
      return;
    }
    dispatch(goToUser("?email=" + query?.name));
  };

  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  const renderUsers = () => {
    if (users && users?.length > 0) {
      return users?.map((user) => (
        <tr key={user._id}>
          <td>
            <Link to={"/users/" + user._id}>
              {user?.firstName} {user?.lastName}
            </Link>
          </td>
          <td>{user?.subscription?.subscription?.name || "N"}</td>
          <td>{user?.charsCount || 0}</td>
          <td>
            {user?.subscription?.expiry
              ? moment(user?.subscription?.expiry).format("DD-MM-YYYY")
              : "N"}
          </td>
          <td>{user?.audiobook?.subscription?.name || "N"}</td>
          <td>{user?.audioBookCharsCount || 0}</td>
          <td>
            {user?.audiobook?.expiry
              ? moment(user?.audiobook?.expiry).format("DD-MM-YYYY")
              : "N"}
          </td>
        </tr>
      ));
    }
    if (loading) {
      return (
        <tr>
          <td colSpan={7}>
            <Spinner color="primary" size="md" />
          </td>
        </tr>
      );
    }
    if (users?.length === 0) {
      return (
        <tr>
          <td colSpan={7}>No Users found</td>
        </tr>
      );
    }
  };

  return (
    <>
      <h4>Get all users</h4>
      {/* {error ? <div className="alert alert-danger">{error}</div> : ""} */}
      {/* <div className="row mb-3">
        <div className="d-flex pl-3 search-con"> 
          <input type="text" placeholder='Search by email' style={{ width: "400px" }} onChange={onSearchChange} className="form-control" />
          {
            dimensions.width > 1200 ? 
            <button className="btn btn-primary ml-2" onClick={onSearchSubmit}>Search</button> :
            <button className="btn icon-btn" onClick={onSearchSubmit}>
              <img src="/search-icon.svg" alt="search-icon" />
            </button>
          }
          {
            dimensions.width > 1200 ? 
            <button className="btn btn-success ml-2" onClick={onGoTo}>Go to</button> :
            <button className="btn icon-btn" onClick={onGoTo}>
              <img src="/user-icon.svg" alt="user-icon" />
            </button>
          }
        </div>
      </div> */}
      <SearchUser
        goToUser={onGoTo}
        searchChange={onSearchChange}
        searchSubmit={onSearchSubmit}
      />
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Plan</th>
              <th scope="col">Chars</th>
              {/* <th scope="col">Start</th> */}
              <th scope="col">End</th>
              <th scope="col">AudioBook</th>
              <th scope="col">Chars</th>
              <th scope="col">End</th>
              {/* <th scope="col">#</th> */}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={7}>
                  <Spinner color="primary" size="md" />
                </td>
              </tr>
            ) : (
              renderUsers()
            )}
          </tbody>
        </table>
      </div>

      <div>
        <PaginationComponent
          count={count}
          limit={query?.limit}
          page={query?.page}
          setPage={handlePageChange}
        />
      </div>
      <Modal
        centered={true}
        isOpen={deleteModal?.show}
        toggle={() => {
          setDeleteModal(undefined);
        }}
      >
        <DeletePrompt
          loading={loading}
          title={"Are you sure you want to delete?"}
          btnText="Delete"
          func={() => deleteModal?.func()}
          toggle={() => {
            setDeleteModal(undefined);
          }}
        />
      </Modal>
    </>
  );
};

export default Users;
