import React, { useState, useEffect } from "react";

function SearchUser({ searchSubmit, searchChange, goToUser }) {
  // ** stats
  const [dimensions, setDimensions] = useState({ width: window.innerWidth });

  // ** Function to handle rezise screen
  const handleResize = () => {
    setDimensions({ width: window.innerWidth });
  };

  // ** use effect to handle resizing screen
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  return (
    <div className="row mb-3">
      <div className="d-flex pl-3 search-con">
        <input
          type="text"
          placeholder="Search by email"
          style={{ width: "400px" }}
          onChange={searchChange}
          className="form-control"
        />
        {dimensions.width > 1200 ? (
          <button className="btn btn-primary ml-2" onClick={searchSubmit}>
            Search
          </button>
        ) : (
          <button className="btn icon-btn" onClick={searchSubmit}>
            <img src="/search-icon.svg" alt="search-icon" />
          </button>
        )}
        {dimensions.width > 1200 ? (
          <button className="btn btn-success ml-2" onClick={goToUser}>
            Go to
          </button>
        ) : (
          <button className="btn icon-btn" onClick={goToUser}>
            <img src="/user-icon.svg" alt="user-icon" />
          </button>
        )}
      </div>
    </div>
  );
}

export default SearchUser;
