import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

import { addVoice } from "../../store/actions";
import { AvForm, AvField } from "availity-reactstrap-validation";
import AvCheckboxGroup from "availity-reactstrap-validation/lib/AvCheckboxGroup";
import AvCheckbox from "availity-reactstrap-validation/lib/AvCheckbox";
import { Spinner } from "reactstrap";
import countries from "../../countries.json";
import languages from "../../languages.json";

const AddVoice = (props) => {
  const { loading, message } = useSelector((state) => ({
    loading: state.voice.loading,
    message: state.voice.message,
  }));
  const dispatch = useDispatch();

  const handleValidSubmit = (e, v) => {
    const { history } = props;
    dispatch(addVoice(v, history));
  };

  const renderCountries = () => {
    if (countries && countries?.length > 0) {
      return countries.map((ctr) => {
        return (
          <option key={ctr?.code} value={ctr?.code}>
            {ctr?.name}
          </option>
        );
      });
    }
  };

  const renderLanguages = () => {
    if (languages && languages?.length > 0) {
      return languages.map((lang) => {
        return (
          <option key={lang?.code} selected={lang?.selected} value={lang?.code}>
            {lang?.name}
          </option>
        );
      });
    }
  };

  return (
    <div className="container content">
      <h4>Add a new voice</h4>
      <hr />
      <div className="row">
        {/* {message && (
          <div className="form-group col-md-12">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )} */}
      </div>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <div className="row">
          <div className="form-group col-md-6 mt-3">
            <AvField
              name="ai_engine"
              label="AI Engine"
              className="form-control"
              placeholder="Enter AI Engine"
              type="select"
              defaultValue="standard"
              required
            >
              <option value="standard">Standard</option>
              <option value="neural">Neural</option>
            </AvField>
          </div>
          <div className="form-group col-md-6 mt-3">
            <AvField
              name="language"
              label="Language"
              className="form-control"
              placeholder="Enter Language"
              type="select"
              defaultValue="en-US"
              required
            >
              {renderLanguages()}
            </AvField>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6 mt-3">
            <AvField
              name="country"
              label="Country"
              className="form-control"
              placeholder="Enter Country"
              type="select"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Country Can't be empty",
                },
              }}
              required
            >
              {renderCountries()}
            </AvField>
          </div>
          <div className="form-group col-md-6 mt-3">
            <AvField
              name="ai_provider"
              label="AI Provider"
              className="form-control"
              placeholder="Enter AI Provider"
              type="select"
              defaultValue="ai101"
              required
            >
              <option value="ai101">AWS</option>
              <option value="ai102">Google</option>
              <option value="ai103">Azure</option>
              <option value="ai104">IBM</option>
            </AvField>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6 mt-3">
            <AvField
              name="ai_voiceId"
              label="AI VoiceId"
              className="form-control"
              placeholder="Enter AI VoiceId"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "AI VoiceId Can't be empty",
                },
              }}
              required
            />
          </div>
          <div className="form-group col-md-6 mt-3">
            <AvField
              name="ai_voiceWebname"
              label="AI VoiceWebname"
              className="form-control"
              placeholder="Enter AI VoiceWebname"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "AI VoiceWebname Can't be empty",
                },
              }}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6 mt-3">
            <AvField
              name="ai_voiceApiname"
              label="AI VoiceApiname"
              className="form-control"
              placeholder="Enter AI VoiceApiname"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "AI VoiceApiname Can't be empty",
                },
              }}
              required
            />
          </div>
          <div className="form-group col-md-6 mt-3">
            <AvField
              name="ai_voiceGender"
              label="AI VoiceGender"
              className="form-control"
              placeholder="Enter AI VoiceGender"
              type="select"
              defaultValue="male"
              required
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </AvField>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12 mt-3">
            <AvCheckboxGroup inline name="effects" label="Voice Effects">
              <AvCheckbox className="lead" label="Effect" value="Effect" />
              <AvCheckbox className="lead" label="Pause" value="Pause" />
              <AvCheckbox className="lead" label="Emphasis" value="Emphasis" />
              <AvCheckbox className="lead" label="Speed" value="Speed" />
              <AvCheckbox className="lead" label="Pitch" value="Pitch" />
              <AvCheckbox className="lead" label="Volume" value="Volume" />
              <AvCheckbox className="lead" label="Say-as" value="Say-as" />
            </AvCheckboxGroup>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12 mt-3">
            <AvCheckboxGroup name="sound_effects" label="Sound Effects">
              <AvCheckbox
                className="lead"
                label="Breathing"
                value="Breathing"
              />
              <AvCheckbox className="lead" label="Emphasis" value="Emphasis" />
              <AvCheckbox className="lead" label="Soft" value="Soft" />
              <AvCheckbox
                className="lead"
                label="Whispered"
                value="Whispered"
              />
              <AvCheckbox
                className="lead"
                label="Conversational"
                value="Conversational"
              />
              <AvCheckbox
                className="lead"
                label="Newscaster"
                value="Newscaster"
              />
              <AvCheckbox className="lead" label="DRC" value="DRC" />
              <AvCheckbox
                className="lead"
                label="Customer Support"
                value="Customer Support"
              />
              <AvCheckbox
                className="lead"
                label="Digital Assistant"
                value="Digital Assistant"
              />
              <AvCheckbox className="lead" label="Happy" value="Happy" />
              <AvCheckbox className="lead" label="Empathic" value="Empathic" />
              <AvCheckbox className="lead" label="Clam" value="Clam" />
            </AvCheckboxGroup>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12 mt-3">
            <label>Is this voice premium?</label>
            <AvField type="checkbox" name="premium" label="Yes" />
          </div>
        </div>
        <button className="btn submit-button" type="submit">
          {loading ? <Spinner size="sm" color="light" /> : ""} Submit
        </button>
      </AvForm>
    </div>
  );
};

export default AddVoice;
