import { tokenConfig } from '../config';
import api from '../../apis/api';

import {
  LIST_SUBSCRIPTIONS,
  GET_SUBSCRIPTION,
  ADD_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  REMOVE_SUBSCRIPTION,
  LIST_PLANS,
  SET_SUBSCRIPTION
} from "./actionTypes";
import { resetError, startAction, stopAction } from '../ui/actions';
import { SET_ERROR, SET_SUCCESS } from '../ui/actionTypes';

export const getSubscription = (subId) => (dispatch, getState) => {
  dispatch(resetError())
  dispatch(startAction(GET_SUBSCRIPTION))
  api.get('/subscriptions/' + subId, tokenConfig(getState))
    .then((response) => {
      dispatch({ type: GET_SUBSCRIPTION, payload: response.data?.data });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(GET_SUBSCRIPTION))
    })
};

export const setSubscription = (subscription) => (dispatch, getState) => {
  dispatch(resetError())
  dispatch(startAction(SET_SUBSCRIPTION))
  dispatch({ type: SET_SUBSCRIPTION, payload: subscription });
};

export const listSubscriptions = (query) => (dispatch, getState) => {
  dispatch(startAction(LIST_SUBSCRIPTIONS))
  dispatch(resetError())
  const queryStr = new URLSearchParams(query).toString()
  api.get(`/subscriptions?${queryStr ? queryStr : ''}`, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: LIST_SUBSCRIPTIONS,
        payload: response.data
      });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(LIST_SUBSCRIPTIONS))
    })
};

export const updateSubscription = (id, data) => (dispatch, getState) => {
  dispatch(resetError())
  dispatch(startAction(UPDATE_SUBSCRIPTION))
  api.put(`/subscriptions/${id}`, data, tokenConfig(getState))
    .then((response) => {
      dispatch({ type: UPDATE_SUBSCRIPTION, payload: response?.data?.data });
      dispatch({ type: SET_SUCCESS, payload: response.data?.message });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(UPDATE_SUBSCRIPTION))
    })
};

export const removeSubscription = (id, callback) => (dispatch, getState) => {
  dispatch(startAction(REMOVE_SUBSCRIPTION))
  api.delete(`/subscriptions/${id}`, tokenConfig(getState))
    .then((response) => {
      dispatch({ type: REMOVE_SUBSCRIPTION, payload: id });
      dispatch({ type: SET_SUCCESS, payload: response.data?.message });
      if (callback) callback()
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(REMOVE_SUBSCRIPTION))
    })
};

export const getPlans = () => (dispatch, getState) => {
  dispatch(startAction(LIST_PLANS))
  api.get(`/subscriptions/plans`, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: LIST_PLANS,
        payload: response?.data
      });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(LIST_PLANS))
    })
};

export const addSubscription = (data, callback) => (dispatch, getState) => {
  dispatch(startAction(ADD_SUBSCRIPTION))
  api.post(`/subscriptions`, data, tokenConfig(getState))
    .then((response) => {
      dispatch({ type: ADD_SUBSCRIPTION, payload: response.data?.data });
      dispatch({ type: SET_SUCCESS, payload: response.data?.message });
      if (callback) callback()
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(ADD_SUBSCRIPTION))
    })
};