import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_LOADING,
  GET_PROFILE_SUCCESS
} from "./actionTypes";

const initialState = {
  token: localStorage.getItem("token"),
  user: null,
  loading: false,
  message: null
}

export default function Auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: payload?.token,
        user: payload?.user,
        loading: false
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false
      };
    case LOGIN_FAIL:
      return {
        ...state,
        token: null,
        loading: false,
        message: payload
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
        message: null
      }
    case LOGOUT:
      return {
        ...state,
        token: null,
        loading: false
      };
    default:
      return state;
  }
}