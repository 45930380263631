import React, { useRef, useState } from "react";

import "./authLayout.css";
import SideBar from "./SideBar";
import Navbar from "./Navbar";

const AuthLayout = ({ children }) => {
  const sidebarRef = useRef(null);
  const [isSidebarToggled, setIsSidebarToggled] = useState(false);

  const handleSideBarToggle = () => {
    setIsSidebarToggled(!isSidebarToggled);
    if (!isSidebarToggled && sidebarRef.current) {
      const collapses = sidebarRef.current.querySelectorAll(".collapse");
      collapses.forEach((collapse) => collapse.classList.remove("show"));
    }
  };

  return (
    <div id="app-wrapper">
      <SideBar
        ref={sidebarRef}
        isSidebarToggled={isSidebarToggled}
        handleSideBarToggle={handleSideBarToggle}
      />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Navbar handleSideBarToggle={handleSideBarToggle} />
          <div className="container">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
