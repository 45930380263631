import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { listVoices, deleteVoice } from "../../store/actions";

import "./voices.css";
import DeletePrompt from "../../components/deletePrompt";
import { Modal, Spinner } from "reactstrap";
import { checkIfLoading } from "../../store/functions";
import { LIST_VOICES_SUCCESS } from "../../store/voices/actionTypes";

import Pagination from "../../components/pagination";

const Voices = (props) => {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(null);

  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    dispatch(listVoices());
  }, []);

  const { voices, loading, count } = useSelector((state) => ({
    voices: state.voice.voices,
    loading: checkIfLoading(state, LIST_VOICES_SUCCESS),
    count: state.voice.count,
  }));

  const onFilterSubmit = (page) => {
    let queryStr = "?";
    let newQuery = query;
    if (!page) newQuery.page = 1;
    for (let [key, value] of Object.entries(query)) {
      if (value && value !== "") {
        queryStr += `${key}=${value}&`;
      }
    }
    queryStr = queryStr.slice(0, -1);
    dispatch(listVoices(queryStr));
  };

  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  useEffect(() => {
    onFilterSubmit(query?.page);
  }, [query.page]);

  const renderVoices = () => {
    if (voices && voices?.length > 0) {
      return voices?.map((voice) => (
        <tr key={voice._id}>
          <td scope="row">{voice?.ai_engine}</td>
          <td scope="row">{voice?.ai_provider}</td>
          <td scope="row">{voice?.ai_voiceApiname}</td>
          <td scope="row">{voice?.ai_voiceGender}</td>
          <td scope="row">{voice?.ai_voiceId}</td>
          <td scope="row">{voice?.ai_voiceWebname}</td>
          <td scope="row">{voice?.country}</td>
          <td scope="row">{voice?.language}</td>
          <td scope="row">
            <Link to={"/voice/" + voice._id}>
              <img alt="" className="action-icons mr-3" src="edit-icon.svg" />
            </Link>
            <img
              onClick={() => {
                setDeleteModal({
                  show: true,
                  func: () => {
                    dispatch(deleteVoice(voice._id));
                    setDeleteModal(null);
                  },
                });
              }}
              alt=""
              className="action-icons"
              src="delete-icon.svg"
            />
          </td>
        </tr>
      ));
    } else {
      return;
    }
  };

  return (
    <div className="container content">
      <h4 className="mb-3">Voices</h4>
      <table className="table table-stripped">
        <thead>
          <tr>
            <th scope="col">Engine</th>
            <th scope="col">Provider</th>
            <th scope="col">VoiceApiname</th>
            <th scope="col">VoiceGender</th>
            <th scope="col">VoiceId</th>
            <th scope="col">VoiceWebname</th>
            <th scope="col">Country</th>
            <th scope="col">Language</th>
            <th scope="col">#</th>
          </tr>
        </thead>
        <tbody>{renderVoices()}</tbody>
      </table>
      {loading ? (
        <div className="text-center">
          <Spinner color="primary" size="md" />
        </div>
      ) : voices?.length === 0 ? (
        "No voices found"
      ) : (
        ""
      )}
      <div>
        <Pagination
          count={count}
          limit={query?.limit}
          page={query?.page}
          setPage={handlePageChange}
        />
      </div>
      <Modal
        centered={true}
        isOpen={deleteModal?.show}
        toggle={() => {
          setDeleteModal(undefined);
        }}
      >
        <DeletePrompt
          loading={loading}
          title={"Are you sure you want to delete?"}
          btnText="Delete"
          func={() => deleteModal?.func()}
          toggle={() => {
            setDeleteModal(undefined);
          }}
        />
      </Modal>
    </div>
  );
};

export default Voices;
