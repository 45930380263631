import {
  GET_SUBSCRIPTION_STATS, GET_VOICE_STATS, STATS_ERROR
} from "./actionTypes";

const initialState = {
  subscriptions: {},
  voices: []
}

export default function Stats(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SUBSCRIPTION_STATS:
      return {
        ...state,
        subscriptions: payload
      };
    case GET_VOICE_STATS:
      return {
        ...state,
        voices: payload
      };
    case STATS_ERROR:
      return {
        ...state,
        error: payload,
        success: null
      }
    default:
      return state;
  }
}