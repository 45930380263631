import {
  LIST_LOGS, LOGS_ERROR
} from "./actionTypes";

const initialState = {
  logs: [],
  count: 0
}

export default function Log(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LIST_LOGS:
      return {
        ...state,
        logs: payload?.logs,
        count: payload?.count
      };
    case LOGS_ERROR:
      return {
        ...state,
        error: payload,
        success: null
      }
    default:
      return state;
  }
}