import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { subscriptionStats, voiceStats } from "../../store/actions";

import "./stats.css";
import { Spinner, Table } from "reactstrap";
import { checkIfLoading } from "../../store/functions";

import {
  GET_SUBSCRIPTION_STATS,
  GET_VOICE_STATS,
} from "../../store/stats/actionTypes";

const Stats = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscriptionStats());
    dispatch(voiceStats());
  }, []);

  const { voices, voicesLoading, subscriptionsLoading, subscriptions } =
    useSelector((state) => ({
      voices: state.stats.voices,
      voicesLoading: checkIfLoading(state, GET_VOICE_STATS),
      subscriptionsLoading: checkIfLoading(state, GET_SUBSCRIPTION_STATS),
      subscriptions: state.stats.subscriptions,
    }));

  const renderVoiceStats = () => {
    if (voices && voices?.length > 0) {
      return voices?.map((voice) => (
        <div className="col-md-3 voicestats" key={crypto.randomUUID()}>
          <div>
            <h5 className="m-0">{voice?.ai_voiceWebname}</h5>
            <span className="text-muted d-block mb-2">
              <small>
                {voice?.ai_voiceGender} - {voice?.language}
              </small>
            </span>
            <label className="d-block">Voices: {voice.voices}</label>
            <label className="d-block">Audiobooks: {voice?.audiobooks}</label>
          </div>
        </div>
      ));
    } else {
      if (voicesLoading)
        return (
          <div className="text-center w-100">
            <Spinner color="primary" size="md" />
          </div>
        );
      else return;
    }
  };

  const renderTableRow = (sub, type) => {
    return (
      <tr key={crypto.randomUUID()}>
        <td>{sub?.day || sub?.date || sub?.year}</td>
        <td>{sub?.basic}</td>
        <td>{sub?.premium}</td>
        <td>{sub?.business}</td>
        <td>{sub?.audiobook}</td>
        <td>{sub?.topups}</td>
        {/* <td>{sub?.audiobookTopups}</td> */}
        <td>{sub?.count}</td>
      </tr>
    );
  };

  const renderSubscriptionStats = () => {
    let html = [];
    if (Object.keys(subscriptions).length > 1) {
      if (subscriptions?.Day?.length) {
        html.push(
          <tr key={crypto.randomUUID()}>
            <td colSpan={7}>Daily</td>
          </tr>
        );
        subscriptions?.Day?.forEach((sub) => {
          html.push(renderTableRow(sub, "day"));
        });
      }
      if (subscriptions?.Week?.length) {
        html.push(
          <tr key={crypto.randomUUID()}>
            <td colSpan={7}>Weekly</td>
          </tr>
        );
        subscriptions?.Week?.forEach((sub) => {
          html.push(renderTableRow(sub, "week"));
        });
      }
      if (subscriptions?.Month?.length) {
        html.push(
          <tr key={crypto.randomUUID()}>
            <td colSpan={7}>Monthly</td>
          </tr>
        );
        subscriptions?.Month?.forEach((sub) => {
          html.push(renderTableRow(sub, "month"));
        });
      }
      if (subscriptions?.Year?.length) {
        html.push(
          <tr key={crypto.randomUUID()}>
            <td colSpan={7}>Yearly</td>
          </tr>
        );
        subscriptions?.Year?.forEach((sub) => {
          html.push(renderTableRow(sub, "year"));
        });
      }
      if (subscriptions?.StartToday) {
        html.push(
          <tr key={crypto.randomUUID()}>
            <td colSpan={7}>Start today</td>
          </tr>
        );
        html.push(renderTableRow(subscriptions?.StartToday));
      }
      if (subscriptions?.StartThisWeek) {
        html.push(
          <tr key={crypto.randomUUID()}>
            <td colSpan={7}>Start this week</td>
          </tr>
        );
        html.push(renderTableRow(subscriptions?.StartThisWeek));
      }
      if (subscriptions?.StartThisMonth) {
        html.push(
          <tr key={crypto.randomUUID()}>
            <td colSpan={7}>Start this month</td>
          </tr>
        );
        html.push(renderTableRow(subscriptions?.StartThisMonth));
      }
      if (subscriptions?.EndToday) {
        html.push(
          <tr key={crypto.randomUUID()}>
            <td colSpan={7}>End today</td>
          </tr>
        );
        html.push(renderTableRow(subscriptions?.EndToday));
      }
      if (subscriptions?.EndThisWeek) {
        html.push(
          <tr key={crypto.randomUUID()}>
            <td colSpan={7}>End this week</td>
          </tr>
        );
        html.push(renderTableRow(subscriptions?.EndThisWeek));
      }
      if (subscriptions?.ExpireThisWeek) {
        html.push(
          <tr key={crypto.randomUUID()}>
            <td colSpan={7}>Expiry this week</td>
          </tr>
        );
        html.push(renderTableRow(subscriptions?.ExpireThisWeek));
      }
      return html;
    }
    if (subscriptionsLoading)
      return (
        <tr>
          <td colSpan={7}>
            <Spinner color="primary" size="md" />
          </td>
        </tr>
      );
  };

  return (
    <div>
      <div className="container content">
        <h2 className="mb-3">Subscription Stats</h2>
        <div className="row">
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Basic</th>
                  <th>Premium</th>
                  <th>Business</th>
                  <th>Audiobook</th>
                  <th>Topups</th>
                  {/* <th>Audiobook topups</th> */}
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>{renderSubscriptionStats()}</tbody>
            </Table>
          </div>
        </div>
      </div>
      <div className="container content">
        <h2 className="mb-3">Voice Stats</h2>
        <div className="row">{renderVoiceStats()}</div>
      </div>
    </div>
  );
};

export default Stats;
