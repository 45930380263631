import {
  VOICES_ERROR,
  ADD_VOICE_SUCCESS,
  EDIT_VOICE_SUCCESS,
  LIST_VOICES_SUCCESS,
  REMOVE_VOICE_SUCCESS,
  GET_VOICE_SUCCESS,
  VOICES_LOADING
} from "./actionTypes";

const initialState = {
  voices: [],
  voice: null,
  message: null,
  count: 0
}

export default function Voice(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LIST_VOICES_SUCCESS:
      return {
        ...state,
        voices: payload?.voices,
        count: payload?.count,
        voice: null,
      };
    case ADD_VOICE_SUCCESS:
      return {
        ...state,
        voices: [...state.voices, payload],
      };
    case REMOVE_VOICE_SUCCESS:
      return {
        ...state,
        message: null,
        voices: state.voices.filter(voice => { return voice._id !== payload })
      }
    case EDIT_VOICE_SUCCESS:
      return {
        ...state,
        voices: state.voices.map(voice => { return voice._id === payload._id ? payload : voice })
      };
    case GET_VOICE_SUCCESS:
      return {
        ...state,
        voice: payload
      };
    case VOICES_ERROR:
      return {
        ...state,
        message: payload
      }
    default:
      return state;
  }
}