import { tokenConfig } from '../config';
import api from '../../apis/api';

import {
  LIST_USERS,
  GET_USER,
  REMOVE_USER,
  EDIT_USER,
  GO_TO_USER,
  LIST_BILLINGS
} from "./actionTypes";
import { resetError, startAction, stopAction } from '../ui/actions';
import { SET_ERROR, SET_SUCCESS } from '../ui/actionTypes';

export const getUser = (user_id) => (dispatch, getState) => {
  dispatch(resetError())
  dispatch(startAction(GET_USER))
  api.get('/users/' + user_id, tokenConfig(getState))
    .then((response) => {
      dispatch({ type: GET_USER, payload: response.data });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(GET_USER))
    })
};

export const listUsers = (query) => (dispatch, getState) => {
  const queryStr = new URLSearchParams(query).toString()
  dispatch(startAction(LIST_USERS))
  dispatch(resetError())
  api.get(`/users?${queryStr ? queryStr : ''}`, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: LIST_USERS,
        payload: response.data
      });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(LIST_USERS))
    })
};

export const goToUser = (query) => (dispatch, getState) => {
  dispatch(resetError())
  dispatch(startAction(GO_TO_USER))
  api.get(`/users/email${query ? query : ''}`, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: GO_TO_USER,
        payload: response.data
      });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(GO_TO_USER))
    })
};

export const editUser = (id, data,callback) => (dispatch, getState) => {
  dispatch(resetError())
  dispatch(startAction(EDIT_USER))
  api.put(`/users/${id}`, data, tokenConfig(getState))
    .then((response) => {
      dispatch({ type: EDIT_USER, payload: response.data });
      dispatch({ type: SET_SUCCESS, payload: response.data?.message });
      callback()
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(EDIT_USER))
    })
};

export const deleteUser = (id) => (dispatch, getState) => {
  dispatch(resetError())
  dispatch(startAction(REMOVE_USER))
  api.delete(`/users/${id}`, tokenConfig(getState))
    .then(() => {
      dispatch({
        type: REMOVE_USER,
        payload: id
      });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(REMOVE_USER))
    })
};

export const getBillings = (id, query) => (dispatch, getState) => {
  dispatch(startAction(LIST_BILLINGS))
  api.get(`/billings/${id}/${query ? query : ''}`, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: LIST_BILLINGS,
        payload: {
          billings: response?.data?.data,
          count: response?.data?.count
        }
      });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(LIST_BILLINGS))
    })
};

export const generateBillingPDF = (id) => (dispatch, getState) => {
  api.put(`/billings/${id}/generate`, tokenConfig(getState))
}