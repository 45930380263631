import { tokenConfig } from '../config';
import api from '../../apis/api';

import { startAction, stopAction } from '../ui/actions';
import { SET_ERROR, SET_SUCCESS } from '../ui/actionTypes';
import { GET_SUBSCRIPTION_STATS, GET_VOICE_STATS } from './actionTypes'

export const subscriptionStats = (query) => (dispatch, getState) => {
  dispatch(startAction(GET_SUBSCRIPTION_STATS))
  api.get(`/stats/subscription`, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: GET_SUBSCRIPTION_STATS,
        payload: response.data
      });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(GET_SUBSCRIPTION_STATS))
    })
};

export const voiceStats = (query) => (dispatch, getState) => {
  dispatch(startAction(GET_VOICE_STATS))
  api.get(`/stats/voice`, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: GET_VOICE_STATS,
        payload: response.data
      });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(GET_VOICE_STATS))
    })
};