import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_LOADING,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
} from "./actionTypes";

import api from "../../apis/api";
import { tokenConfig } from "../config";

export const login = (data, history) => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });
  api
    .post("/auth/signin", data)
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("token", response?.data?.token);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response?.data,
        });
      }
    })
    .catch((error) => {
      const message = error?.response?.data?.message;
      dispatch({ type: LOGIN_FAIL, payload: message });
    });
};

export const getProfile = () => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });
  api
    .get("/auth/profile", tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: GET_PROFILE_SUCCESS,
        payload: response?.data?.data,
      });
    })
    .catch((error) => {
      if (error?.response?.status === 401) dispatch(logout());
    });
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("token");

  dispatch({
    type: LOGOUT,
  });
};
