import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { editUser, getBillings, getUser } from "../../store/actions";

import { AvForm, AvField } from "availity-reactstrap-validation";
import { Spinner } from "reactstrap";
import { checkIfLoading } from "../../store/functions";
import {
  EDIT_USER,
  GET_USER,
  LIST_BILLINGS,
} from "../../store/users/actionTypes";
import moment from "moment";
import "./user.css";

import PaginationComponent from "../../components/pagination";
import api from "../../apis/api";
import { tokenConfig } from "../../store/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFloppyDisk,
  faPenToSquare,
} from "@fortawesome/free-regular-svg-icons";
import Subscriptions from "./subscriptions/subscriptions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const User = (props) => {
  const dispatch = useDispatch();
  const [editAcc, setEditAcc] = useState(false);
  const { history } = props;
  const { id } = useParams();

  const [query, setQuery] = useState({
    name: "",
    page: 1,
    limit: 10,
  });

  const {
    user,
    token,
    loading,
    editLoading,
    billings,
    billingLoading,
    billingsCount,
    toUser,
  } = useSelector((state) => ({
    user: state?.user?.user,
    token: state?.auth?.token,
    error: state?.ui?.error,
    success: state?.ui?.success,
    loading: checkIfLoading(state, GET_USER),
    editLoading: checkIfLoading(state, EDIT_USER),
    billingLoading: checkIfLoading(state, LIST_BILLINGS),
    billings: state?.user?.billings,
    toUser: state?.user?.toUser,
    billingsCount: state?.user?.billingsCount,
  }));

  useEffect(() => {
    if (!id) return history.push("/users");
    dispatch(getUser(id));
    dispatch(getBillings(id, ""));
  }, [id]);

  useEffect(() => {
    if (toUser && !loading) {
      history.push("/users/" + toUser?._id);
    }
  }, [toUser]);

  const updateAccount = (e, v) => {
    dispatch(editUser(user?._id, v, () => setEditAcc(false)));
  };

  const renderBillings = () => {
    if (!billingLoading) {
      if (billings && billings?.length > 0) {
        return billings?.map((billing) => {
          return (
            <tr key={crypto.randomUUID()}>
              <td>{billing?.number}</td>
              <td>{moment(billing?.createdAt).format("DD MMM YYYY")}</td>
              <td>
                {billing?.subscription?.type} ({billing?.type})
              </td>
              <td>
                {billing?.card}
              </td>
              <td>
                {billing?.coupon?.discount ? (
                  <b>{Number(billing?.coupon?.discount) * 100}%</b>
                ) : (
                  "Not Used"
                )}
              </td>
              <td>
                {billing?.price} {billing?.currency}
              </td>
              <td>
                <img
                  alt=""
                  onClick={() => onDownloadClick(billing?._id)}
                  className="action-icons mr-3"
                  src="/download-icon.svg"
                />
              </td>
            </tr>
          );
        });
      } else {
        return (
          <tr>
            <td colSpan={7}>No Billings found</td>
          </tr>
        );
      }
    }
  };

  const RedirectPage = (url) => {
    window.location.replace(url);
  };

  const onDownloadClick = async (id) => {
    api
      .put(`/billings/${id}/generate`, {}, tokenConfig({ token }))
      .then((data) => {
        if (data?.data?.success) RedirectPage(data.data?.data);
      });
  };

  const onFilterSubmit = (page) => {
    const id = history?.location?.pathname?.split("/users/")[1];
    let queryStr = "?";
    let newQuery = query;
    if (!page) newQuery.page = 1;
    for (let [key, value] of Object.entries(query)) {
      if (value && value !== "") {
        queryStr += `${key}=${value}&`;
      }
    }
    queryStr = queryStr.slice(0, -1);
    dispatch(getBillings(id, queryStr));
  };

  useEffect(() => {
    onFilterSubmit(query?.page);
  }, [query.page]);

  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  return (
    <div className="container">
      <h4 className="mb-1">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={() => history.push("/users")}
          width={60}
          role="button"
        />
        Edit User {user?.firstName} {user?.lastName}
      </h4>
      <hr />
      {loading ? (
        <div className="w-100 text-center">
          <Spinner color="primary" size="md" />
        </div>
      ) : (
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            updateAccount(e, v);
          }}
        >
          <div className="editBox mb-3">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="mb-0">Account Settings</h4>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  className="mr-2 cursor-pointer"
                  onClick={() => setEditAcc(!editAcc)}
                  icon={faPenToSquare}
                />
                <button
                  className="btn btn-link p-0"
                  disabled={!editAcc || editLoading}
                >
                  {editLoading ? (
                    <Spinner size="sm" color="primary" />
                  ) : (
                    <FontAwesomeIcon icon={faFloppyDisk} />
                  )}
                </button>
              </div>
            </div>
            <div className="d-flex row">
              <div className="form-group col-lg-4">
                <AvField
                  name="fullName"
                  label="Full name"
                  className="form-control"
                  placeholder="enter fullName"
                  disabled={!editAcc}
                  type="text"
                  value={user?.firstName + " " + user?.lastName}
                  required
                />
              </div>
              <div className="form-group col-lg-4">
                <AvField
                  name="email"
                  label="Email"
                  className="form-control"
                  placeholder="enter email"
                  disabled={!editAcc}
                  type="text"
                  value={user?.email}
                  required
                />
              </div>
              <div className="form-group col-lg-4">
                <label>User Verified?</label>
                <AvField
                  disabled={!editAcc}
                  defaultChecked={user?.verified}
                  type="checkbox"
                  name="verified"
                  label="Yes"
                />
              </div>
            </div>
          </div>
        </AvForm>
      )}
      {/* {
              subscriptions?.map(sub => <UpdateSubscriptionForm subscription={sub} deleteModal={deleteModal} setDeleteModal={setDeleteModal} />)
            } */}
      <div className="text-right">
        <button
          className="btn btn-primary mb-2"
          onClick={() =>
            history.push(`/subscriptions/create?user=${user?._id}`)
          }
        >
          Add subscription
        </button>
      </div>
      <Subscriptions userId={id} />
      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">DATE</th>
              <th scope="col">PLAN</th>
              <th scope="col">CARD</th>
              <th scope="col">COUPON</th>
              <th scope="col">AMOUNT</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {billingLoading ? (
              <tr>
                <td colSpan={7}>
                  <Spinner color="primary" size="md" />
                </td>
              </tr>
            ) : (
              renderBillings()
            )}
          </tbody>
        </table>
      </div>
      <div>
        <PaginationComponent
          count={billingsCount}
          limit={query?.limit}
          page={query?.page}
          setPage={handlePageChange}
        />
      </div>
    </div>
  );
};

export default User;
