import { tokenConfig } from '../config';
import api from '../../apis/api';

import { LIST_LOGS } from "./actionTypes";
import { startAction, stopAction } from '../ui/actions';
import { SET_ERROR, SET_SUCCESS } from '../ui/actionTypes';

export const listLogs = (query) => (dispatch, getState) => {
  dispatch(startAction(LIST_LOGS))
  api.get(`/logs${query ? query : ''}`, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: LIST_LOGS,
        payload: response.data
      });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(LIST_LOGS))
    })
};