import axios from "axios";
import { toast } from "react-toastify";
import { logout } from "../store/actions";
import store from "../store";
console.log(process.env.REACT_APP_API_URL)

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.message && (response.data?.success ?? true)) {
      toast.success(response.data.message ?? "Done Successfully");
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      const { dispatch } = store;

      dispatch(logout());
    }
    toast.error(error.response.data.message ?? "An unexpected error occurred!");
    return Promise.reject(error);
  }
);

export default axiosInstance;
