import {
  LIST_SUBSCRIPTIONS,
  GET_SUBSCRIPTION,
  REMOVE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  ADD_SUBSCRIPTION,
  LIST_PLANS,
  SET_SUBSCRIPTION
} from "./actionTypes";

const initialState = {
  subscriptions: [],
  count: 0,
  subscription: null,
  success: null,
  error: null,
  plans: []
}

export default function User(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LIST_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: payload?.subscriptions,
        count: payload?.count
      };
    case ADD_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: [...state.subscriptions, action.payload]
      };
    case REMOVE_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: state?.subscriptions?.filter(sub => sub._id !== payload)
      }
    case GET_SUBSCRIPTION:
    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload
      }
    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: state?.subscriptions?.map(sub => sub._id === action.payload?._id ? action.payload : sub)
      }
    case LIST_PLANS:
      return {
        ...state,
        plans: payload
      };
    default:
      return state;
  }
}