import { tokenConfig } from '../config';
import api from '../../apis/api';

import { LIST_FAILED_LONG_AUDIO_BOOKS } from "./actionTypes"
import { startAction, stopAction } from '../ui/actions';
import { SET_ERROR } from '../ui/actionTypes';

export const listFailedLongAudioBooks = (query) => (dispatch, getState) => {
  dispatch(startAction(LIST_FAILED_LONG_AUDIO_BOOKS))
  api.get(`/audioBooksLongs/failed${query ? query : ''}`, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: LIST_FAILED_LONG_AUDIO_BOOKS,
        payload: response.data
      });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: SET_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(LIST_FAILED_LONG_AUDIO_BOOKS))
    })
};