import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  addSubscription,
  getPlans,
  getSubscription,
  removeSubscription,
  setSubscription,
  updateSubscription,
} from "../../store/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFloppyDisk,
  faPenToSquare,
} from "@fortawesome/free-regular-svg-icons";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Modal, Spinner } from "reactstrap";
import {
  GET_SUBSCRIPTION,
  LIST_PLANS,
  REMOVE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} from "../../store/subscriptions/actionTypes";
import { faChevronLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import { checkIfLoading } from "../../store/functions";
import moment from "moment";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import DeletePrompt from "../../components/deletePrompt";
import { userSubscriptionStatus } from "../../store/types";

const SubscriptionType = {
  FREE: "free",
  BASIC: "basic",
  PREMIUM: "premium",
  BUSINESS: "business",
  AUDIO_BOOK: "audioBook",
  PAY_AS_YOU_GO: "pay-as-you-go",
  STORAGE: "storage",
  MAIN_CHARACTERS: "main-characters",
  AUDIOBOOK_CHARACTERS: "audiobook-characters",
};

const SubscriptionPage = ({}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [deleteModal, setDeleteModal] = useState(null);
  const history = useHistory();
  const user = history?.location?.search?.split("?user=")[1];

  const {
    subscription,
    planLoading,
    updateSubLoading,
    plans,
    deleteLoading,
    getLoading,
  } = useSelector((state) => ({
    subscription: state?.subscription?.subscription,
    updateSubLoading: checkIfLoading(state, UPDATE_SUBSCRIPTION),
    planLoading: checkIfLoading(state, LIST_PLANS),
    plans: state.subscription?.plans,
    deleteLoading: checkIfLoading(state, REMOVE_SUBSCRIPTION),
    getLoading: checkIfLoading(state, GET_SUBSCRIPTION),
  }));

  const renderStatusOptions = () => {
    return Object.entries(userSubscriptionStatus).map((s) => {
      return (
        <option key={crypto.randomUUID()} value={s[1]}>
          {s[1]}
        </option>
      );
    });
  };

  useEffect(() => {
    if (subscription) {
      if (subscription?.start)
        subscription.start = moment(subscription?.start).format("YYYY-MM-DD");
      if (subscription?.expiry)
        subscription.expiry = moment(subscription?.expiry).format("YYYY-MM-DD");
    }
  }, [subscription]);

  const getFilteredPlans = (type, plans) => {
    const mainPlan =
      type === "main" ||
      type === SubscriptionType.BASIC ||
      type === SubscriptionType.PREMIUM ||
      type === SubscriptionType.BUSINESS;
    const audiobookPlan = type === SubscriptionType.AUDIO_BOOK;
    const freePlan = type === SubscriptionType.FREE;
    const characters = String(type).includes("characters");

    if (mainPlan)
      return plans?.filter(
        (p) =>
          p.type === SubscriptionType.BASIC ||
          p.type === SubscriptionType.PREMIUM ||
          p.type === SubscriptionType.BUSINESS
      );
    if (audiobookPlan)
      return plans?.filter((p) => p.type === SubscriptionType.AUDIO_BOOK);
    if (freePlan) return plans?.filter((p) => p.type === SubscriptionType.FREE);
    if (characters)
      return plans?.filter(
        (p) =>
          p.type === SubscriptionType.AUDIOBOOK_CHARACTERS ||
          p.type === SubscriptionType.MAIN_CHARACTERS
      );
  };

  const renderPlans = (type) => {
    let plansHtml = [
      <option key={crypto.randomUUID()} disabled value={null}>
        Please choose plan
      </option>,
    ];
    let filteredPlans = plans;

    if (type) {
      filteredPlans = getFilteredPlans(type, plans);
      if (filteredPlans && filteredPlans?.length > 0) {
        filteredPlans?.forEach((plan) => {
          plansHtml.push(
            <option key={plan?._id} value={plan?._id}>
              {plan?.name}
            </option>
          );
        });
      }

      return plansHtml;
    } else {
      const mainPlans = getFilteredPlans("main", plans);
      const audiobookPlans = getFilteredPlans("audioBook", plans);
      const characterPlans = getFilteredPlans("characters", plans);

      plansHtml.push(
        <option key={crypto.randomUUID()} disabled value={null}>
          Main plans
        </option>
      );
      mainPlans?.forEach((plan) => {
        plansHtml.push(
          <option key={plan?._id} value={plan?._id}>
            {plan?.name}
          </option>
        );
      });

      plansHtml.push(
        <option key={crypto.randomUUID()} disabled value={null}>
          Audiobook plans
        </option>
      );
      audiobookPlans?.forEach((plan) => {
        plansHtml.push(
          <option key={plan?._id} value={plan?._id}>
            {plan?.name}
          </option>
        );
      });

      plansHtml.push(
        <option key={crypto.randomUUID()} disabled value={null}>
          Character plans
        </option>
      );
      characterPlans?.forEach((plan) => {
        plansHtml.push(
          <option key={plan?._id} value={plan?._id}>
            {plan?.name}
          </option>
        );
      });

      return plansHtml;
    }
  };

  useEffect(() => {
    if (id) dispatch(getSubscription(id));
    else dispatch(setSubscription(null))
  }, [id]);

  useEffect(() => {
    dispatch(getPlans());
  }, []);

  const onUpdateSubscription = (e, v) => {
    v.charsCount = Number(v.charsCount);
    v.charsLimit = Number(v.charsLimit);
    if (id) {
      dispatch(updateSubscription(subscription?._id, v));
    } else {
      if (user)
        dispatch(
          addSubscription({ ...v, user }, () => history?.push(`/users/${user}`))
        );
    }
  };

  return (
    <div className="container">
      <div className="mb-2">
        <button
          className="btn btn-primary btn-sm"
          onClick={() => history.goBack()}
        >
          <FontAwesomeIcon icon={faChevronLeft} /> Return to user
        </button>
      </div>
      {getLoading ? (
        <div className="text-center mt-3">
          <Spinner color="primary" size={"md"} />
        </div>
      ) : (
        <AvForm
          className="form-horizontal mb-3"
          onValidSubmit={(e, v) => {
            onUpdateSubscription(e, v);
          }}
        >
          <div className="editBox">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="m-0">
                {subscription && id
                  ? subscription?.subscription?.type +
                    " - " +
                    (subscription?.action || "New")
                  : "New subscription"}
              </h4>
              {subscription?.status !== userSubscriptionStatus.EXPIRED ? (
                <div>
                  <button className="btn btn-link" disabled={updateSubLoading}>
                    {updateSubLoading ? (
                      <Spinner size="sm" color="primary" />
                    ) : (
                      <FontAwesomeIcon icon={faFloppyDisk} />
                    )}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-inline align-items-center justify-content-between mb-2">
                  <label className="m-0 mr-4">Subscription</label>
                  <AvField
                    name="subscription"
                    className="form-control"
                    placeholder="Choose Subscription"
                    disabled={updateSubLoading}
                    type="select"
                    value={subscription?.subscription?._id}
                  >
                    {renderPlans(subscription?.subscription?.type)}
                    {/* {planLoading ? <Spinner /> :} */}
                  </AvField>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inline align-items-center justify-content-between mb-2">
                  <label className="m-0 mr-4">Status</label>
                  <AvField
                    name="status"
                    className="form-control"
                    placeholder="Choose Status"
                    disabled={updateSubLoading}
                    type="select"
                    value={subscription?.status || "active"}
                  >
                    {renderStatusOptions()}
                  </AvField>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-inline align-items-center justify-content-between">
                  <label className="mr-3">Subscription On</label>
                  <AvField
                    name="start"
                    className="form-control"
                    placeholder="Subscribed At"
                    value={moment(subscription?.start).format("YYYY-MM-DD")}
                    disabled={updateSubLoading}
                    type="date"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inline align-items-center justify-content-between">
                  <label className="mr-3">Subscription end</label>
                  <AvField
                    name="expiry"
                    className="form-control"
                    placeholder="Subscribe End"
                    value={moment(subscription?.expiry).format("YYYY-MM-DD")}
                    disabled={updateSubLoading}
                    type="date"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6">
                <div className="form-inline align-items-center justify-content-between">
                  <label>Characters Count</label>
                  <AvField
                    name="charsCount"
                    className="form-control"
                    placeholder="Characters Count"
                    value={subscription?.charsCount || 0}
                    disabled={updateSubLoading}
                    type="number"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inline align-items-center justify-content-between">
                  <label>Characters Limit</label>
                  <AvField
                    name="charsLimit"
                    className="form-control"
                    placeholder="Characters Limit"
                    value={subscription?.charsLimit || 0}
                    disabled={updateSubLoading}
                    type="number"
                  />
                </div>
              </div>
              <div className="col-md-6">
                {subscription?.autoPay ? (
                  <button className="btn btn-link">Cancel auto pay</button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="text-right">
              {subscription &&
              subscription?.status !== userSubscriptionStatus.EXPIRED ? (
                <button
                  type="button"
                  onClick={() => {
                    setDeleteModal({
                      show: true,
                      func: () => {
                        dispatch(
                          removeSubscription(subscription?._id, () => {
                            setDeleteModal(null);
                            history.push(`/users/${subscription?.user?._id}`);
                          })
                        );
                      },
                    });
                  }}
                  disabled={deleteLoading}
                  style={{ position: "relative", top: "8px" }}
                  className="btn btn-link text-danger"
                >
                  {deleteLoading ? (
                    <Spinner color="primary" size="sm" />
                  ) : (
                    <FontAwesomeIcon icon={faTrash} />
                  )}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </AvForm>
      )}
      <Modal
        isOpen={deleteModal?.show}
        centered
        toggle={() => setDeleteModal(null)}
      >
        <DeletePrompt
          func={deleteModal?.func}
          title="Delete Subscription"
          loading={deleteLoading}
          message="Are you sure you want to delete?"
          toggle={() => setDeleteModal(null)}
          btnText="Delete"
        />
      </Modal>
    </div>
  );
};

export default SubscriptionPage;
