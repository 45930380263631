export const AuthRoleTypes = {
  ADMIN: "admin",
  ACCOUNT_MANAGER: "account_manager"
}

export const userSubscriptionStatus = {
  ACTIVE: "active",
  EXPIRED: "expired",
  PENDING: "pending",
  CANCELLED: "cancelled",
  UPDATED: 'updated',
  CANCELLED_BY_ADMIN: "cancelled by admin"
}