import React, { useEffect, useRef, useState } from "react";
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { getVoice, editVoice } from "../../store/actions";

import { AvForm, AvField } from "availity-reactstrap-validation"
import AvCheckboxGroup from "availity-reactstrap-validation/lib/AvCheckboxGroup";
import AvCheckbox from "availity-reactstrap-validation/lib/AvCheckbox";
import { Spinner } from "reactstrap";
import { checkIfLoading } from "../../store/functions";
import { GET_VOICE_SUCCESS } from "../../store/voices/actionTypes";
import countries from '../../countries.json'
import languages from '../../languages.json'

const Voice = (props) => {
  const dispatch = useDispatch()

  const { voice, message, loading } = useSelector(state => ({
    voice: state?.voice?.voice,
    message: state?.voice?.message,
    loading: checkIfLoading(state, GET_VOICE_SUCCESS)
  }))

  useEffect(() => {
    const { history } = props
    const id = history?.location?.pathname?.split('/voice/')[1]
    if (!id) return history.push('/voices')
    dispatch(getVoice(id))
  }, [])

  const handleValidSubmit = (e, v) => {
    const { history } = props
    // return console.log(v)
    dispatch(editVoice(voice?._id, v, history))
  }

  const renderCountries = () => {
    if(countries && countries?.length > 0){
      return countries.map(ctr => {
        return <option key={ctr?.code} value={ctr?.code}>{ctr?.name}</option>
      })
    }
  }

  const renderLanguages = () => {
    if(languages && languages?.length > 0){
      return languages.map(lang => {
        return <option key={lang?.code} selected={lang?.selected} value={lang?.code}>{lang?.name}</option>
      })
    }
  }

  return (
    <div className="container">
      <h4>Add a new voice</h4>
      <hr />
      <div className="row">
        {message && (
          <div className="form-group col-md-6">
            <div className="alert alert-success" role="alert">
              {message}
            </div>
          </div>
        )}
      </div>
      {
        loading ? <Spinner color="primary" size="md" /> :
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v)
            }}
            model={voice}
          >
            <div className="row">
              <div className="form-group col-md-6 mt-3">
                <AvField
                  name="ai_engine"
                  label="AI Engine"
                  className="form-control"
                  placeholder="Enter AI Engine"
                  type="select"
                >
                  <option value="">Please choose</option>
                  <option value="standard">Standard</option>
                  <option value="neural">Neural</option>
                </AvField>
              </div>
              <div className="form-group col-md-6 mt-3">
                <AvField
                  name="language"
                  label="Language"
                  className="form-control"
                  placeholder="Enter Language"
                  type="select"
                >
                  {renderLanguages()}
                </AvField>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 mt-3">
                <AvField
                  name="country"
                  label="Country"
                  className="form-control"
                  placeholder="Enter Country"
                  type="select"
                  validate={{ required: { value: true, errorMessage: "Country Can't be empty" } }}
                >
                  {renderCountries()}
                </AvField>
              </div>
              <div className="form-group col-md-6 mt-3">
                <AvField
                  name="ai_provider"
                  label="AI Provider"
                  className="form-control"
                  placeholder="Enter AI Provider"
                  type="select"
                >
                  <option value="">Please choose</option>
                  <option value="ai101">AWS</option>
                  <option value="ai102">Google</option>
                  <option value="ai103">Azure</option>
                  <option value="ai104">IBM</option>
                </AvField>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 mt-3">
                <AvField
                  name="ai_voiceId"
                  label="AI VoiceId"
                  className="form-control"
                  placeholder="Enter AI VoiceId"
                  type="text"
                  validate={{ required: { value: true, errorMessage: "AI VoiceId Can't be empty" } }}
                  required
                />
              </div>
              <div className="form-group col-md-6 mt-3">
                <AvField
                  name="ai_voiceWebname"
                  label="AI VoiceWebname"
                  className="form-control"
                  placeholder="Enter AI VoiceWebname"
                  type="text"
                  validate={{ required: { value: true, errorMessage: "AI VoiceWebname Can't be empty" } }}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 mt-3">
                <AvField
                  name="ai_voiceApiname"
                  label="AI VoiceApiname"
                  className="form-control"
                  placeholder="Enter AI VoiceApiname"
                  type="text"
                  validate={{ required: { value: true, errorMessage: "AI VoiceApiname Can't be empty" } }}
                  required
                />
              </div>
              <div className="form-group col-md-6 mt-3">
                <AvField
                  name="ai_voiceGender"
                  label="AI VoiceGender"
                  className="form-control"
                  placeholder="Enter AI VoiceGender"
                  type="select"
                >
                  <option value="">Please choose</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Male (child)">Male (child)</option>
                  <option value="Female (child)">Female (child)</option>
                </AvField>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 mt-3">
                <AvCheckboxGroup inline name="effects" label="Voice Effects">
                  <AvCheckbox label="Effect" value="Effect" />
                  <AvCheckbox label="Pause" value="Pause" />
                  <AvCheckbox label="Emphasis" value="Emphasis" />
                  <AvCheckbox label="Speed" value="Speed" />
                  <AvCheckbox label="Pitch" value="Pitch" />
                  <AvCheckbox label="Volume" value="Volume" />
                  <AvCheckbox label="Say-as" value="Say-as" />
                </AvCheckboxGroup>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 mt-3">
                <AvCheckboxGroup name="sound_effects" label="Sound Effects">
                  <AvCheckbox className="lead" label="Breathing" value="Breathing" />
                  <AvCheckbox className="lead" label="Emphasis" value="Emphasis" />
                  <AvCheckbox className="lead" label="Soft" value="Soft" />
                  <AvCheckbox className="lead" label="Whispered" value="Whispered" />
                  <AvCheckbox className="lead" label="Conversational" value="Conversational" />
                  <AvCheckbox className="lead" label="Newscaster" value="Newscaster" />
                  <AvCheckbox className="lead" label="DRC" value="DRC" />
                  <AvCheckbox className="lead" label="Customer Support" value="Customer Support" />
                  <AvCheckbox className="lead" label="Digital Assistant" value="Digital Assistant" />
                  <AvCheckbox className="lead" label="Happy" value="Happy" />
                  <AvCheckbox className="lead" label="Empathic" value="Empathic" />
                  <AvCheckbox className="lead" label="Clam" value="Clam" />
                </AvCheckboxGroup>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 mt-3">
                <label>Is this voice premium?</label>
                <AvField type="checkbox" name='premium' label="Yes" />
              </div>
            </div>
            <button className="btn btn-primary" type="submit">
              Submit {loading ? <Spinner size="sm" color="light" /> : ""}
            </button>
          </AvForm>
      }
    </div>
  );
}

export default Voice;