import {
  EDIT_USER,
  LIST_USERS,
  REMOVE_USER,
  GET_USER,
  GO_TO_USER,
  LIST_BILLINGS,
} from "./actionTypes";

const initialState = {
  users: [],
  count: 0,
  user: null,
  toUser: null,
  success: null,
  error: null,
  plans: []
}

export default function User(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LIST_USERS:
      return {
        ...state,
        users: payload?.users,
        count: payload?.count,
        user: null,
        toUser: null
      };
    case GO_TO_USER:
      return {
        ...state,
        toUser: payload
      };
    case REMOVE_USER:
      return {
        ...state,
        message: null,
        users: state.users.filter(user => { return user._id !== payload })
      }
    case EDIT_USER:
      return {
        ...state,
        users: state.users.map(user => { return user._id === payload?.user._id ? payload?.user : user }),
        success: payload?.message,
        error: null
      };
    case GET_USER:
      return {
        ...state,
        toUser: null,
        user: { ...state?.user, ...payload },
        error: null
      };
    case LIST_BILLINGS:
      return {
        ...state,
        billings: payload?.billings,
        billingsCount: payload?.count
      };
    default:
      return state;
  }
}