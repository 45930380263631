import React, { useEffect } from "react";
import { logout } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { getProfile } from "../store/actions";

const Navbar = ({ handleSideBarToggle }) => {
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
  };

  const { token, user } = useSelector((state) => ({
    user: state?.auth?.user,
    token: state.auth?.token,
  }));

  useEffect(() => {
    if (token) dispatch(getProfile());
  }, [dispatch, token]);

  return (
    <nav className="navbar navbar-light navbar-expand topbar shadow mb-4">
      <div className="container d-flex align-items-center">
        <button
          onClick={handleSideBarToggle}
          className={`btn btn-link d-sm-none mr-3`}
        >
          <FontAwesomeIcon icon={faBars} fontSize={"22px"} />
        </button>
        {token && (
          <div className="navbar-nav ml-auto">
            {user?.username} |&nbsp;
            <a
              href="/login"
              style={{
                color: "var(--neon)",
                textDecoration: "none",
                fontSize: "inherit",
              }}
              onClick={() => logOut()}
            >
              Log out
            </a>
          </div>
        )}
      </div>
    </nav>
  );
};
export default Navbar;
