import { tokenConfig } from '../config';
import api from '../../apis/api';

import {
  VOICES_ERROR,
  ADD_VOICE_SUCCESS,
  LIST_VOICES_SUCCESS,
  GET_VOICE_SUCCESS,
  REMOVE_VOICE_SUCCESS,
  EDIT_VOICE_SUCCESS
} from "./actionTypes";
import { startAction, stopAction } from '../ui/actions';

export const getVoice = (voice_id) => (dispatch, getState) => {
  dispatch(startAction(GET_VOICE_SUCCESS))
  api.get('/voices/' + voice_id, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: GET_VOICE_SUCCESS,
        payload: response.data
      });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: VOICES_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(GET_VOICE_SUCCESS))
    })
};

export const listVoices = (query) => (dispatch, getState) => {
  dispatch(startAction(LIST_VOICES_SUCCESS))
  api.get(`/voices${query ? query : ''}`, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: LIST_VOICES_SUCCESS,
        payload: response.data
      });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: VOICES_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(LIST_VOICES_SUCCESS))
    })
};

export const addVoice = (data, history) => (dispatch, getState) => {
  dispatch(startAction(ADD_VOICE_SUCCESS))
  api.post('/voices/', data, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: ADD_VOICE_SUCCESS,
        payload: response.data.data
      });
      history.push('/voices')
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: VOICES_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(ADD_VOICE_SUCCESS))
    })
};

export const editVoice = (id, data, history) => (dispatch, getState) => {
  dispatch(startAction(EDIT_VOICE_SUCCESS))
  api.put(`/voices/${id}`, data, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: EDIT_VOICE_SUCCESS,
        payload: response.data.voice
      });
      return history.push('/voices')
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: VOICES_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(EDIT_VOICE_SUCCESS))
    })
};

export const deleteVoice = (id) => (dispatch, getState) => {
  dispatch(startAction(REMOVE_VOICE_SUCCESS))
  api.delete(`/voices/${id}`, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: REMOVE_VOICE_SUCCESS,
        payload: id
      });
    }).catch((error) => {
      const message = error?.response?.data?.message

      dispatch({
        type: VOICES_ERROR,
        payload: message
      });
    }).finally(() => {
      dispatch(stopAction(REMOVE_VOICE_SUCCESS))
    })
};