import React, { Component, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { login } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import api from "../../apis/api";

const Login = (props) => {
  const { token, message, loading } = useSelector((state) => ({
    token: state.auth.token,
    message: state.auth.message,
    loading: state.auth.loading,
  }));
  const dispatch = useDispatch();

  const handleValidSubmit = (e, v) => {
    const { history } = props;
    dispatch(login(v, history));
  };

  if (token) {
    return <Redirect to="/voices" />;
  }

  return (
    <div className="vh-50">
      <div className="h-100">
        <div className="col-md-12">
          <div className="login-container">
            <div className="text-center pb-5">
              <img src="/logo.svg" width={"200px"} alt="logo" />
            </div>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <div className="form-group">
                <AvField
                  name="username"
                  label="Username"
                  className="form-control"
                  placeholder="Enter Username"
                  type="text"
                  required
                />
              </div>
              <div className="form-group">
                <AvField
                  name="password"
                  label="Password"
                  className="form-control"
                  placeholder="Enter Password"
                  type="password"
                  required
                />
              </div>
              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  disabled={loading}
                >
                  {loading ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : (
                    "Login"
                  )}
                </button>
              </div>

              {/* {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )} */}
            </AvForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
