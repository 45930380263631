import React from "react";
import { useSelector } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./App-responsive.css";

import Login from "./pages/login/login";
import AddVoice from "./pages/addVoice/add-voice";
import GetVoices from "./pages/voices/voices";
import Users from "./pages/users/users";
import Voice from "./pages/voice/voice";

import { history } from "./helpers/history";
import User from "./pages/user/user";
import Logs from "./pages/logs/logs";
import Stats from "./pages/stats/stats";
import FailedLongAudioBooks from "./pages/failedLongAudioBooks/failedLongAudioBooks"
import AuthLayout from "./components/AuthLayout";
import SubscriptionPage from "./pages/subscription/subscription";
import { ToastContainer, toast } from "react-toastify";
toast.configure();

const App = () => {
  const { token } = useSelector((state) => ({
    token: state.auth.token,
  }));

  const returnRoutes = (token) => {
    if (token) {
      return (
        <AuthLayout>
          <Switch>
            <Route exact path="/voice/:id" component={Voice} />
            <Route exact path="/voices" component={GetVoices} />
            <Route exact path="/add-voice" component={AddVoice} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/users/:id" component={User} />
            {/* <Route exact path="/subscriptions" component={Subscriptions} /> */}
            <Route
              exact
              path="/subscriptions/create"
              component={SubscriptionPage}
            />
            <Route
              exact
              path="/subscriptions/:id"
              component={SubscriptionPage}
            />
            <Route exact path="/logs" component={Logs} />
            <Route exact path="/stats" component={Stats} />
            <Route exaact path="/failedAudioBooksLongs" component={FailedLongAudioBooks} />
            <Redirect to="/users" />
          </Switch>
        </AuthLayout>
      );
    } else {
      return (
        <Switch>
          <Route exact path="/login" component={Login} />
          <Redirect to="/login" />
        </Switch>
      );
    }
  };

  return (
    <Router history={history}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
      />
      <>{returnRoutes(token)}</>
    </Router>
  );
};

// M9N1XuFlNEIg7ISH

export default App;
