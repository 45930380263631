import {
  RESET,
  SET_ERROR,
  SET_SUCCESS,
  START_ACTION,
  STOP_ACTION
} from './actionTypes';

export const startAction = (name, params) => ({
  type: START_ACTION,
  payload: {
    action: {
      name,
      params
    }
  }
});

export const stopAction = name => ({
  type: STOP_ACTION,
  payload: { name }
});

export const setSuccess = success => ({
  type: SET_SUCCESS,
  payload: success
});

export const setError = error => ({
  type: SET_ERROR,
  payload: error
});

export const resetError = () => ({
  type: RESET,
  payload: {}
});