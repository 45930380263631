import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faArrowRight,
  faList,
  faUniversity,
  faCircleExclamation
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SideBar = forwardRef(
  ({ handleSideBarToggle, isSidebarToggled }, sidebarRef) => {
    const location = useLocation();

    return (
      <ul
        ref={sidebarRef}
        className={`${
          isSidebarToggled ? " toggled" : " "
        } sidebar navbar-nav bg-gradient-primary sidebar sidebar-dark accordion`}
        id="accordionSidebar"
      >
        <Link
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="index.html"
          to="/"
        >
          <div className="sidebar-brand-icon rotate-n-15">
            <img src="/logoIcon.svg" width={"50px"} alt="" />
          </div>
          <div className="sidebar-brand-text mx-3">
            <img src="/logo.svg" width={"120px"} alt="" />
          </div>
        </Link>
        <li
          className={`nav-item ${
            location.pathname?.includes("/users") ? "active" : ""
          }`}
        >
          <Link to={"/users"} className="nav-link text-white">
            <FontAwesomeIcon icon={faUser} className="mr-2" />
            <span>Users</span>
          </Link>
        </li>
        <li
          className={`nav-item ${
            location.pathname?.includes("/logs") ? "active" : ""
          }`}
        >
          <Link to={"/logs"} className="nav-link text-white">
            <FontAwesomeIcon icon={faList} className="mr-2" />
            <span>Logs</span>
          </Link>
        </li>
        <li
          className={`nav-item ${
            location.pathname?.includes("/stats") ? "active" : ""
          }`}
        >
          <Link to={"/stats"} className="nav-link text-white">
            <FontAwesomeIcon icon={faUniversity} className="mr-2" />
            <span>Stats</span>
          </Link>
        </li>
        <li
          className={`nav-item ${
            location.pathname?.includes("/failedAudioBooksLongs") ? "active" : ""
          }`}
        >
          <Link to={"/failedAudioBooksLongs"} className="nav-link text-white">
            <FontAwesomeIcon icon={faCircleExclamation} className="mr-2" />
            <span>Failed long audio books</span>
          </Link>
        </li>
        <hr className="sidebar-divider my-3" />
        <div className={"text-center"}>
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={handleSideBarToggle}
          >
            <FontAwesomeIcon
              icon={isSidebarToggled ? faArrowRight : faArrowLeft}
            />
          </button>
        </div>
      </ul>
    );
  }
);

export default SideBar;
